module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/KCrisanto/Documents/3m-redesign/code/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"328d123b235f8d711a029240f4c67921"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"partnershipId":"4b68854a-8f40-44d2-82c6-e20d152767a0","extraDevHostnames":[],"extraStageHostnames":[],"extraProdHostnames":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
